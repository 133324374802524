.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 40px;
    font-weight: 700;
    color: white;
    text-align: center;
    margin-top: 20px;
}

.description {
    font-size: 30px;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-top: 20px;
}

.green {
    color: #56CE2C;
}

.productsContainer {
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
}