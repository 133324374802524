.container {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 160px;
    padding-bottom: 160px;
}

.phoneContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 65vh;
    aspect-ratio: 0.4931;
}

.phoneFrame {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: -15px;
}

.videoRec {
    border-radius: 4.5vh;
    position: absolute;
    width: 92%;
    height: 97.5%;
    object-fit: cover;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 40px;
    font-weight: 700;
    color: white;
    text-align: center;
    margin-top: 20px;
}

.description {
    font-size: 30px;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-top: 20px;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.featuresContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border-radius: 20px;
    background-image: linear-gradient(to bottom right, #4CAF9AAA, #4CAF9A55);
}

.featuresTitle {
    color: #FFD700;
    font-weight: 600;
}

.feature {
    font-size: 20px;
    font-weight: 500;
    color: white;
    margin-top: 10px;
}

.qrCodeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    cursor: pointer;
}

.qrCode {
    width: 200px;
    height: 200px;
    border-radius: 30px;
}

.qrCodeText {
    font-size: 20px;
    font-weight: 500;
    color: white;
    margin-top: 10px;
    white-space: pre;
}

.green {
    color: #56CE2C;
}

@media screen and (max-width: 1400px) {
    .container {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}