@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 2;
}

.workoutContainer {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 2;
}

.crochetContainer {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 2;
}


.navbarBackground {
    height: 100px;
    width: 100vw;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.workoutNavbarBackground {
    height: 100px;
    width: 100vw;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.crochetNavbarBackground {
    height: 100px;
    width: 100vw;
    background-image: linear-gradient(to right, #000000, #00000000, #00000000, #00000000, #00000000);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.shadow {
    height: 60px;
    margin-bottom: -60px;
    width: 100vw;
    background-image: linear-gradient(to bottom, #000000, #00000000);
}

.workoutShadow {
    height: 60px;
    margin-bottom: -60px;
    width: 100vw;
    background-image: linear-gradient(to bottom, #000000, #00000000);
}

.crochetShadow {
    // height: 60px;
    // margin-bottom: -60px;
    // width: 100vw;
    // background-image: linear-gradient(to bottom, #000000, #00000000);
}

.companyLogoContainer {
    margin: 0 2rem;
    z-index: 2;
}

.workoutCompanyLogoContainer {
    margin: 0 2rem;
    z-index: 2;
}

.crochetCompanyLogoContainer {
    margin: 0 2rem;
    z-index: 2;
}


.secondaryLogoContainer {}

.workoutSecondaryLogoContainer {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
}

.crochetSecondaryLogoContainer {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
}