.container {
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border-radius: 1.2rem;
    background-color: white;
    cursor: pointer;
    margin-left: 2rem;
    margin-right: 2rem;

    @media (max-width: 768px) {
        margin-bottom: 2rem;
    }
}

.image {
    width: 90%;
    aspect-ratio: 1;
    border-radius: 0.8rem;
    margin-bottom: 1rem;
}

.title {
    font-size: 2rem;
    font-weight: 500;
    color: #111;
    margin-bottom: 0.5rem;
}