@import "./../consts/LAYOUT.scss";

.container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    z-index: 1000;
}

.image {
    height: 60px;
    aspect-ratio: 4 / 1.4;
}