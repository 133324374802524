@import "./../consts/LAYOUT.scss";

.container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    z-index: 1000;
}

.image {
    // width: 456px;
    height: 80px;
    aspect-ratio: 456/366;
}